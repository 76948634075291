import React, { useEffect } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import moment from "moment-timezone";

import { useFormData } from "../../../hooks/useFormData";

const ArrivalModal = ({ show, toggle, job, onSave }) => {
  const { formData, onChange, setData } = useFormData({
    arrivalTime: null,
    endTime: null,
    enabled: true,
    start: moment(),
    end: moment(),
    notes: "",
  });

  useEffect(() => {
    if (!job) {
      return;
    }

    const dt = job.dates.arrivalDateTime
      ? moment.utc(job.dates.arrivalDateTime).local()
      : moment.utc(job.dates.startDateTime).local();


    const start = moment.utc(job.dates.startDateTime).local();
    const end = moment(start).add(job.hours, "hours");

    setData({
      arrivalTime: dt.format("HH:mm"),
      arrivalDateTime: dt.format(),
      start,
      end,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const onSubmit = (e) => {
    e.preventDefault();
    onSave({ ...formData });
    toggle();
  };

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Header>
        <h3>Confirm Your Arrival Time</h3>
      </Modal.Header>
      <Modal.Body>
        <form id="arrival-modal-form" onSubmit={onSubmit} className="mt-3">
          <p>Please confirm your acceptance of the booking on:</p>
          <h5 className="mb-4">
            {formData.start.format("ddd, MMM Do")} at{" "}
            {formData.start.format("h:mm A")} until{" "}
            {formData.end.format("h:mm A")}
          </h5>

          <Form.Group className="mb-3 mt-3">
            <Form.Label>Additional Notes/Comments</Form.Label>
            <Form.Control
              as="textarea"
              name="notes"
              value={formData.notes}
              onChange={onChange}
              placeholder="Additional notes/comments..."
              style={{ minHeight: 150 }}
            />
          </Form.Group>
        </form>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button
          type="submit"
          form="arrival-modal-form"
          variant="primary"
          style={{ width: 200 }}
          disabled={!formData.enabled}
        >
          ACCEPT JOB
        </Button>
        <Button
          variant="link"
          onClick={() => {
            toggle();
          }}
        >
          CANCEL
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArrivalModal;
